import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, HeadingStyled, Span, SpanStyled, TextStyled } from './Elements';
import { Facebook, Instagram, NextArrow, Twitter } from './AllImages';
import { Link } from 'react-router-dom';
import CustomModal from '../sections/about/ModalProcess';
import { useTranslation } from 'react-i18next';


export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach(prop => delete otherProps[prop]);
    return otherProps;
}

export const ButtonWithIcon = (props) => {
    const { t } = useTranslation();
    const unwantedProps = ['icon', 'label', 'portClass', 'customClass'];
    return (
        <ButtonWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center ${props.customClass ? props.customClass : ''}`}>
            <button className={`btn btn-primary ${props.portClass ? props.portClass : '' } ${!props.icon ? 'btn-icon' : ''} `}>
                <SpanStyled color='var(--white)' size='16px' family='var(--semiBold)' >{props.label ? t(props.label) : ''}</SpanStyled>
                {props.icon && (
                    <props.icon />
                )}
            </button>
        </ButtonWrapper>
    )
}

export const TabButton = (props) => {
    const unwantedProps = ['label'];
    return (
        <TabButtonWrapper>
            <button {...sliceProps(props, unwantedProps)}>
                {props.label ? props.label : ''}
            </button>
        </TabButtonWrapper>
    )
}
export const TabButtonWrapper = styled.div`
    button{
        display: flex;
        align-items: center;
        color: #99A1B9;
        background: rgba(255, 255, 255, .7);
        border: none;
        padding: 8px 30px;
        border-radius: 22px;
        font-size: 16px;
        font-family: var(--regular);
        transition: all .4s ease-in-out;
        &.active{
            /* Add the blur effect */
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            color: var(--white);
            background: var(--bgGradient);
            // background: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
            -webkit-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
            -moz-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
            box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
            // border: 1px solid transparent;
        }
        &:focus{
            /* Add the blur effect */
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            color: var(--white);
            background: var(--bgGradient);
            // background: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
            -webkit-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
            -moz-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
            box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
            // border: 1px solid transparent;
        }
        &:hover{
            /* Add the blur effect */
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            color: var(--white);
            background: var(--bgGradient);
            // background: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
            -webkit-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
            -moz-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
            box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
            // border: 1px solid transparent;
        }
        
    }

  
`

export const ContactButton = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ContactWrapper {...sliceProps(props, unwantedProps)}>
            <button  className={`click-btn btn-style501 ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                {props.icon && (
                    <props.icon width='22px' />
                )}
                <HeadingStyled color='var(--WhiteColor)' transform='math-auto' size='14px' weight='normal'>{props.label ? props.label : ''}</HeadingStyled>
            </button>
        </ContactWrapper>
    )
}
export const InputWithIcon = (props) => {
    const unwantedProps = ['icon', "label"];
    return (
        <InputStyledWrap className='position-relative' >
            <InputStyled {...sliceProps(props, unwantedProps)} className={`w-100 ${!props.icon ? 'no-icon' : ''}`} autoComplete='false' />
            {props.icon && (
                <props.icon width='18px' className='position-absolute input-svg' />
            )}
        </InputStyledWrap>
    )
}
export const WhatsappMain = (props) => {
    const WhatsApp = () => {
        const phoneNumber = '0581506065';
        const message = 'Hello, FOWL';
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const unwantedProps = ["label", "icon"];
    return (
        <WhatsappContainer {...sliceProps(props, unwantedProps)} className='whatsappContainer'>
            <Box className="whatsappBg">
                <Box className='whatsappFlag'>
                    {props.icon && (
                        <props.icon className='whatsappSvg' />
                    )}      
                </Box>
                <SpanStyled className='me-2 wtspNumber' size='16px' family='var(--semiBold)' color='var(--darkBlue)'>0581506065</SpanStyled>
                <ButtonWithIcon customClass="whatsappButton" label={props.label ? props.label : ''}  onClick={WhatsApp} />
            </Box>
            
        </WhatsappContainer>
    )
}

export const WhatsappContainer = styled.div`
    .whatsappBg{
        display: flex;
        background: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
        /* Add the blur effect */
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        padding: 10px;
        border-radius: 30px;
        align-items: center;
        gap: 10px;
        width: fit-content;
        border-1px solid var(--white);
        -webkit-box-shadow: 0px 10px 15px 0px rgba(82,80,237,0.2);
        -moz-box-shadow: 0px 10px 15px 0px rgba(82,80,237,0.2);
        box-shadow: 0px 10px 15px 0px rgba(82,80,237,0.2);
        .whatsappSvg{
            width: 35px;
            height: 35px;
        }
    }
`

export const PrimaryHeading = (props) => {
    const unwantedProps = ["HlabelF", "HlabelS"];
    return (
        <HeadingContainer {...sliceProps(props, unwantedProps)} className={`headingContainer ${props.customClass ? props.customClass : '' }`}>
            <span className="HeadingFirst">
                {props.HlabelF}
            </span>
            <span className="HeadingSecond">
                {props.HlabelS}
            </span>
        </HeadingContainer>
    )
}


const HeadingContainer = styled.div`
    display: block;
    width: 100%;
    .HeadingSecond{
        font-size: ${props => props.size ? props.size : '37px'};
        background: var(--bgGradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: var(--bold);
    }
    .HeadingFirst{
        font-size: ${props => props.size ? props.size : '37px'};
        color: var(--darkBlue);
        font-family: var(--light);
    }
`

export const ProcessSocialLinks = (props) => {
    const { t } = useTranslation();
    const unwantedProps = ["HlabelF", "HlabelS"];
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    return (
        <ProcessSocialContainer className='processSocialContainer' {...sliceProps(props, unwantedProps)}>
            <ButtonWithIcon label={t('Our_Process')} icon={NextArrow} onClick={handleShowModal} />
            <CustomModal show={showModal} onHide={handleCloseModal} />
            <Box className="processBorder"></Box>
            <Box className='processSocial'>
                <Link><Facebook /></Link>
                <Link><Twitter /></Link>
                <Link><Instagram /></Link>
            </Box>
        </ProcessSocialContainer>
    )
}
const ProcessSocialContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 30px;
    align-items: center;
    gap: 30px;
    .processBorder{
        display: flex;
        width: 100%;
        height: 1px;
        background: var(--white);
    }
    .processSocial{
        display: flex;
        gap: 12px;
        a{
            display: flex;
            svg{
                max-width: 45px;
                min-width: 45px;
                height: 45px
            }
        }
    }
`

export const TextWithIcon = (props) => (
    <TextWithStyledWrapper color={props.color} className={`d-flex ${props.desc ? 'align-items-start' : 'align-items-center my-2'}`}>
        <Box className='mx-2 d-flex align-items-center justify-content-center svg'>
            <props.icon width='20px' height='20px' />
        </Box>
        <Box>
            <Box className='mb-0 contact-text' size={props.headingSize ? props.headingSize : '14px'}
                color={!props.alsotextColor ? 'var(--gray)' : 'var(--white)'}
                dangerouslySetInnerHTML={{ __html: props.title }}>
            </Box>
            {props.desc ?
                <TextStyled className='text-uppercase' color={'var(--gray)'} size='14px' >
                    {props?.desc}
                </TextStyled>
                : null}
        </Box>
    </TextWithStyledWrapper>
)

export const ListPoints = (props) => {
    const unwantedProps = ['ulClass', 'liClass', 'portClass'];
    return (
        <ListWrapper {...sliceProps(props, unwantedProps)} className={`d-flex ${props.ulClass ? props.ulClass : '' }`}>
            <li {...sliceProps(props, unwantedProps)} className={`unList ${props.liClass ? props.liClass : '' } `}>
                <SpanStyled color='var(--darkBlue)' size='15px' >{props.label ? props.label : ''}</SpanStyled>
            </li>
        </ListWrapper>
    )
}

const ListWrapper = styled.ul`
  
`

export const TextWithIcons = (props) => {
    const unwantedProps = ['icon', 'label', 'customClass'];
    return (
        <TextWithIconWrappers {...sliceProps(props, unwantedProps)} className={`d-flex align-items-start ${props.customClass ? props.customClass : '' } `}>
            {props.icon && (
                <img className='twi-img' src={props.icon} />
            )}
            <SpanStyled color='var(--darkBlue)' size='15px' family='var(--regular)' >{props.label ? props.label : ''}</SpanStyled>
            
        </TextWithIconWrappers>
    )
}
export const TextWithIconWrappers = styled.div`
    gap: 10px;
    // margin-bottom: 20px;
    
`
export const CustomCheckbox = (props) => {
    const id = Math.floor(100000 + Math.random() * 900000);
    const unwantedProps = ["label", "labelClass", "checked", "value", "onChange"];
    return (
      <CustomCheckboxStyled
        className="mb-0 customCheck"
        {...sliceProps(props, unwantedProps)}
      >
        <input
          type="checkbox"
          checked={props.checked}
          value={props.value}
          onChange={props.onChange}
          name={props.name}
        />
        <span className={`checkLabel ${props.labelClass}`}>
          {props.label}
        </span>
        <span className="checkmark"></span>
      </CustomCheckboxStyled>
    );
  };

  export const CustomCheckboxStyled = styled.label`
  display: flex;
  position: relative;
  padding-left: 35px;
  min-height: 23px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkLabel{
    cursor: pointer;
  line-height: 22px;
  display: inline-block;
  color: var(--darkBlue);
  font-size: 14px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    border-radius: 6px;
    border: 1px solid transparent;
    background-color: var(--white);
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 3px;
      width: 6px;
      height: 13px;
      border: solid var(--purple);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  &:hover {
    input ~ .checkmark {
      background-color: var(--white);
      border-color: var(--purple);
    }
  }
  input:checked ~ .checkmark {
    background-color: var(--white);
    border-color: var(--purple);
  }
`;



const TextWithStyledWrapper = styled.div`
  svg {
      [stroke] {
          stroke: ${({ color }) => color ? color : ''};
      }
  }
`
export const InputStyled = styled.input`
    background:var(--bgColor);
    border: none;
    border-radius: 8px;
    height: 43px;
    // color: var(--textGrey);
    &:not(.no-icon) {
        padding-left: 50px;
    }
    &.no-icon {
        padding-left: 18px;
    }
    padding-right: 18px;
    outline: none;
    &,&::placeholder {
        font-size: 14px;
        color: var(--textPalceholder);
    }
`
export const InputStyledWrap = styled.div`
    border-radius: 8px;
    background: var(--white);
    svg {
    left: 13px;
    top: 12px;
    height: 20px;
    }
`


export const ContactWrapper = styled.span`
button{
    color:var(--white);
    background-color: ${props => props.bg ? props.bg : 'var(--yellow)'};
    border: none;
    padding: 4px 22px;
    border-radius: 10px;
    transition: all .4s ease-in-out;
    &:hover{
       opacity:0.8;
    }
}
    
    
`
export const ButtonWrapper = styled.div`
    button{
        display: flex;
        align-items: center;
        color:var(--white);
        background: ${props => props.bg ? props.bg : 'var(--bgGradient)'};
        border: none;
        padding: ${props => props.padding ? props.padding : '8px 22px'};
        border-radius: ${props => props.radius ? props.radius : '22px'};
        gap: 15px;
        font-size: 16px;
        white-space: nowrap;
        font-family: var(--semiBold);
        transition: all .4s ease-in-out;
        &:hover{
            opacity:0.8;
        }
        svg{
            margin-right: -12px
        }
    }

  
`