import React, { useState } from 'react'
import Slider from 'react-slick'
import { Col, Container, Row, Modal, Button } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { ButtonWithIcon, PrimaryHeading, TabButton } from '../../Styled/CustomElements'
import { Cross, FeatherBall, NextArrow, Process1, Process2, Process3, Process4, Process5, Process6, ServiceIcon1, Union1, Union2, aboutIcon } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import MobileDevelopments from './MobileDevelopments'
import { servicesModal } from '../../data/Data'




const CustomModal = ({ show, onHide, modalId }) => {
    
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '0581506065';
        const message = 'Hello, FOWL';
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <>
        <Modal 
            show={show && modalId !== null} 
            onHide={onHide}
            centered
            size="xl"
            className="custom-modal"
            // id={0}
        >
            
            <Modal.Body>
                <Button variant="secondary" onClick={onHide} className='crossModal'>
                    <Cross />
                </Button>
                <section>
                    <Container className='containerPr container-fluid'>
                        <Row className='leftRightColWrap services-wrap'>
                            <Box className='leftColIco leftColIcoServices'>
                                
                                {servicesModal[modalId].mainImg}
                                {servicesModal[modalId].ele1}
                                {servicesModal[modalId].ele2}
                                <Box className='modalElement modalElement1'></Box>
                                <Box className='modalElement modalElement2'></Box>
                                <img className='servicesModImg servicesFeather' src={FeatherBall} />
                            </Box>
                            <Box className='rightColContent rightColContentServices'>
                                <TabsWrapper className='TabsServicesWrapper'>
                    
                                    <Box className='modalHeading'>
                                        <PrimaryHeading HlabelF={t(servicesModal[modalId].headLabelF)} HlabelS={t(servicesModal[modalId].headLabelS)} size='42px' />
                                    </Box>
                                    
                                    <SpanStyled size='15px' className='mb-5'>
                                        {t(servicesModal[modalId].detPara1)}
                                        {/* <br></br><br></br>
                                        {t(servicesModal[modalId].detPara2)} */}
                                    </SpanStyled>
                                    {/* <MobileDevelopments /> */}
                                    {servicesModal[modalId].desDev}
                                    <ButtonWithIcon label={t('Get_early_access')} customClass="viewProfile" onClick={WhatsApp} />
                                </TabsWrapper>

                            </Box>
                            
                        </Row>

                    </Container>
                </section>
            </Modal.Body>
            
        </Modal>
        
        </>
    );
};

export default CustomModal;
const TabsWrapper = styled.div`
    

`