import React, { useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { Box, SpanStyled, } from '../../Styled/Elements'
import { ListPoints, PrimaryHeading, ProcessSocialLinks, } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { TabsWrapper } from './Styled'
import styled from 'styled-components'
import { BeepList, BeepVisitList, MobileDevItems } from '../../data/Data'
import { Link } from 'react-router-dom'

const PrMoreDetail = () => {
    const { t } = useTranslation();
    
    return (
        <>
            <Col lg={12} className='sthDesc mb-1'>
                <Box className="smallTitleHeading">
                    {t('Quick')}
                </Box>
                <SpanStyled size='15px' className='projectDesc'>
                    {t('QuickTxt')}
                </SpanStyled>
            </Col>
            <Col lg={12} className='sthDesc mb-1'>
                <Box className="smallTitleHeading">
                    {t('Anywhere')}
                </Box>
                <SpanStyled size='15px' className='projectDesc'>
                    {t('AnywhereTxt')}
                </SpanStyled>
            </Col>
            <Col lg={12} className='sthDesc mb-1'>
                <Box className="smallTitleHeading">
                    {t('Bookings')}
                </Box>
                <SpanStyled size='15px' className='projectDesc'>
                    {t('BookingsTxt')}
                </SpanStyled>
            </Col>
            <Col lg={12} className='sthDesc mb-2'>
                <Box className="smallTitleHeading">
                    {t('Communication')}
                </Box>
                <SpanStyled size='15px' className='projectDesc'>
                    {t('CommunicationTxt')}
                </SpanStyled>
            </Col>
        </>
    )
}

export default PrMoreDetail
