import React, { useRef } from 'react'
import Slider from 'react-slick'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { WhatsappMain } from '../../Styled/CustomElements'
import { Flag, Element1, Element2, Element3, Element4 } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import { bannerItems } from '../../data/Data'

const Banner = () => {
    const { t } = useTranslation();

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        //prevArrow: <ClientLeftArrow />,
        //nextArrow: < ClientRightArrow />,
        responsive: [
            {
                // breakpoint: 1200,
                // settings: {
                //     slidesToShow: 4,
                //     slidesToScroll: 1,
                //     infinite: true,
                // }
            }
            
        ]
    }
    return (
        <BannerWrapper className='section-common banner-bg' id='home'>
            <Container className='container-fluid'>
                <Row className='banner-wrap'>
                    <Slider {...settings}>
                        {bannerItems.map((item, index) => (
                            <Box>
                                <Row key={index} className='banner-content-row'>

                                    <Col lg={6} sm={12} className='banner-content-col'>
                                        <Box className='banner-content'>
                                            <Box className='bannertTxtPrimary'>
                                                <SpanStyled className='bannertTxtPrimary1'>{t(item.mainTxt)}</SpanStyled>
                                                <SpanStyled className='bannertTxtPrimary2'>{t(item.mainTxtB)}</SpanStyled>
                                            </Box>
                                            <Box className='bannertHeading'>{t(item.mainBannerHeading)}</Box>
                                            <Box className='bannertTxtSecondary'>
                                                {t(item.secondaryTxt)}
                                            </Box>
                                            <WhatsappMain icon={Flag} label={t('Get_early_access')} />
                                        </Box>
                                    </Col>
                                    <Col lg={6} sm={12} className='banner-img-container'>
                                        <Box className='banner-img'>
                                            {item.img}
                                            <Element1 className='element element1' />
                                            <Element2 className='element element2' />
                                            {/* <Element3 className='element element3' /> */}
                                            <Element4 className='element element4' />
                                            
                                            {/* <Element3 className='element element5' /> */}
                                        </Box>
                                    </Col>  
                                           
                                </Row>
                            </Box>
                        ))}
                    </Slider>
                    {/* 
                    <Col lg={6} md={12} className=''>
                        <Box className='banner-content'>
                            <MainHeading className='main-heading' color='var(--white)' weight='var(--medium)'>{t('Process_for')}</MainHeading>
                            <MainHeading className='main-heading' color='var(--yellow)'>{t('Seamless_Procurement')}</MainHeading>
                            <TextStyled color='var(--white)'>
                                {t('Simplify_procurement')}
                            </TextStyled>
                            <SpanStyled className='py-0' weight='var(--regular)' size='14px' color='var(--lightText)'>
                                {t('No_Card_Required')}
                            </SpanStyled>
                        </Box>
                    </Col>
                    <Col lg={8} md={12}>
                        <Box className='banner-dashboard'>
                            
                        </Box>
                    </Col>
                     */}
                </Row>
            </Container>
        </BannerWrapper>
    )
}

export default Banner
const BannerWrapper = styled.section`
    .banner-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        // height: 100vh;
        padding-top: 30px
    }
    .banner-content{
        // display: flex;
        // flex-direction: column;
        // gap: 5px;
        // width: 85%;
    }
    .banner-dashboard{
        position: relative;
        text-align: end;
    }
    .banner-items-1{
        position: absolute;
        top: -30px;
        right: 0;
        left: 110px;
        margin: auto;
    }
    }
    .banner-items-2{
        position: absolute;
        top: 100px;
        bottom: 0;
        left: -60px;
        margin: auto;
    }

`