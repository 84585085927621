import React, { useState } from 'react'
import Slider from 'react-slick'
import { useForm } from "react-hook-form"
import { Col, Container, Row, Modal, Button } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { ButtonWithIcon, PrimaryHeading, TabButton } from '../../Styled/CustomElements'
import { Cross, Email, FName, FeatherBall, GetQuoteEle1, GetQuoteEle2, GetQuoteEle3, GetQuoteEle4, GetQuoteMain, Message, NextArrow, Phone, Process1, Process2, Process3, Process4, Process5, Process6, ServiceIcon1, Union1, Union2, aboutIcon } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

import { servicesModal } from '../../data/Data'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContactFormWrapper, GetQuoteSection } from './Styled'
import ContactForm from './GetQuoteForm'


const CustomModal = ({ show, onHide }) => {
    
    const { t } = useTranslation();
    

    return (
        <>
        <Modal 
            show={show} 
            onHide={onHide}
            centered
            size="xl"
            className="custom-modal-Quote"
            // id={0}
        >
            
            <Modal.Body>
                <Button variant="secondary" onClick={onHide} className='crossModal'>
                    <Cross />
                </Button>
                <GetQuoteSection>
                    <Container className='containerPr container-fluid'>
                        <Row className='leftRightColWrap getQuote-wrap'>
                            <Box className='leftColIco leftColIcoGetQuote'>
                                <Box className='contatcHeading'>
                                    <PrimaryHeading HlabelF={t("Get_a")} HlabelS={t("Quote")} />
                                </Box>
                                <SpanStyled size='15px' className='mb-4 contactTxt'>
                                    {t('getQuoteText')}
                                </SpanStyled>
                                <Box className='quoteEleWrapper'>
                                    <img src={GetQuoteMain} alt='Get Quote Image' className="scaleAnim getQuoteMain" />
                                    <img src={GetQuoteEle1} alt='Get Quote Image' className='scaleAnim modalElement modalQuoEle1' />
                                    <img src={GetQuoteEle2} alt='Get Quote Image' className='scaleAnim modalElement modalQuoEle2' />
                                    <img src={GetQuoteEle3} alt='Get Quote Image' className='scaleAnim modalElement modalQuoEle3' />
                                    <img src={GetQuoteEle4} alt='Get Quote Image' className='scaleAnim modalElement modalQuoEle4' />

                                    <Box className='modalElement modalElement3'></Box>
                                    <Box className='modalElement modalElement4'></Box>
                                </Box>
                                
                            </Box>
                            <Box className='rightColContent rightColContentQuote'>
                                <ContactForm />

                            </Box>
                            
                        </Row>

                    </Container>
                </GetQuoteSection>
            </Modal.Body>
            
        </Modal>
        
        </>
    );
};

export default CustomModal;
const TabsWrapper = styled.div`
    

`