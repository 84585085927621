import { Link as ScrollLink } from 'react-scroll'
import { Box, LazyImage, SpanStyled } from '../Styled/Elements'
import { HeaderContainer } from './Styled'
import { NavDataConfig } from '../data/Data'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsJustify, BsX } from "react-icons/bs"
import { Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { ButtonWithIcon } from '../Styled/CustomElements'
import { Logo, Quote } from '../Styled/AllImages'
import ChangeButton from '../../Languages/ChangeButton'
import CustomModal from '../sections/getaQuote/ModalGetQuote'
const Header = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    // const WhatsApp = () => {
    //     const phoneNumber = '+966580911057';
    //     const message = 'Hello, Advanced Housing';
    //     const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    //     window.open(whatsappURL, '_blank');
    // };
    const navigate = useNavigate();
    // const { t } = useTranslation();
    const [isSticky, setIsSticky] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
        const element = document.querySelector(".nav-bar-list");
        element.classList.toggle("showToggle");
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
          };

        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll, handleResize);
        return () => {
            window.removeEventListener('scroll', handleScroll, handleResize);
        };
    }, []);

      

    return (
        <>
        <HeaderContainer className={`${isSticky ? 'sticky' : ''} container-fluid`}>
            <Container>
                <Row>
                    <Box className='nav-bar'>
                        <Box className='logo' onClick={() => navigate('/home')}>
                            <Logo />
                        </Box>
                        <Box className='nav-bar-right'>
                            <Box className="nav-bar-list-container">
                                <Box className='nav-bar-list'>
                                
                                    {NavDataConfig.map((item, key) => (
                                        
                                        <Box key={key}>
                                            {screenWidth <= 991 && (
                                                <ScrollLink to={item.to} spy={true} smooth={true} offset={-10} duration={400} activeClass="active" onClick={handleToggle} >{t(item.label)}</ScrollLink>
                                            )}
                                            {screenWidth > 991 && (
                                                <NavLink to={item.to} activeClassName="active" onClick={handleToggle} >{t(item.label)}</NavLink>
                                            )}
                                        </Box>
                                        
                                    ))}
                                    
                                </Box>
                                
                            </Box>
                            <Box className='nav-bar-btns'>
                                <button className='header-btn' onClick={() => setModalShow(true)}>
                                    <LazyImage className='quote-img' src={Quote} />
                                    <SpanStyled className="get-a-quote">{t('Get_a_Quote')}</SpanStyled>
                                </button>
                            </Box>
                            <Box className='d-flex align-items-center'>
                                    
                                <span className="toggleBtn" onClick={handleToggle}>
                                    {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                                </span>
                            </Box>
                        </Box>
                    </Box>
                    
                </Row>
            </Container>
            <CustomModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </HeaderContainer>
        <ChangeButton />
        </>
    )
}


export default Header
