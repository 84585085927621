import React, { useState } from 'react'
import Slider from 'react-slick'
import { Col, Container, Row, Modal, Button } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { PrimaryHeading, TabButton } from '../../Styled/CustomElements'
import { Cross, Process1, Process2, Process3, Process4, Process5, Process6, Union1, Union2 } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import { bannerItems } from '../../data/Data'
import TabFirst from './Tab1'
import TabSecond from './Tab2'
import { expertiseItems } from '../../data/Data'
import TabThird from './Tab3'

const CustomModal = ({ show, onHide }) => {
    const { t } = useTranslation();
    return (
        <Modal 
            show={show} 
            onHide={onHide}
            centered
            size="xl"
            className="custom-modal"
        >
            
            <Modal.Body>
                <Button variant="secondary" onClick={onHide} className='crossModal'>
                    <Cross />
                </Button>
                <Container className='containerPr'>
                    <Row className="PRRow">
                        <Col md={12}>
                            <Box className='processHeading'>
                                <PrimaryHeading HlabelF={t("OurP")} HlabelS={t("ProcessP")} customClass="text-center" />
                            </Box>
                            <Box className='processText'>
                                <SpanStyled size='15px' className='text-center'>
                                    {t('processModText')}
                                </SpanStyled>
                            </Box>

                            <Box className="processWrapper">
                                <Union1 className='union1' />
                                <Union2 className='union2' />
                                <Box className="processIconsWrapper">
                                    <Box className="processIcons processIcons1">
                                        <Box className="processIcon">
                                            <Box className="processIconInner">
                                                <img src={Process1} alt="process icon" />
                                            </Box>
                                            <Box className="processNumber">
                                                <span>1</span>
                                            </Box>
                                            <Box className="processDetailHeading processDetailHeading1">{t('Idea')}</Box>
                                        </Box>
                                    </Box>
                                    
                                    <Box className="processIcons processIcons2">
                                        <Box className="processIcon">
                                            <Box className="processIconInner">
                                                <img src={Process2} alt="process icon" />
                                            </Box>
                                            <Box className="processNumber">
                                                <span>2</span>
                                            </Box>
                                            <Box className="processDetailHeading processDetailHeading2">{t('Specs')}</Box>
                                        </Box>
                                    </Box>
                                    <Box className="processIcons processIcons3">
                                        <Box className="processIcon">
                                            <Box className="processIconInner">
                                                <img src={Process3} alt="process icon" />
                                            </Box>
                                            <Box className="processNumber">
                                                <span>3</span>
                                            </Box>
                                            <Box className="processDetailHeading processDetailHeading3">{t('Visual_Design')}</Box>
                                        </Box>
                                    </Box>
                                    <Box className="processIcons processIcons4">
                                        <Box className="processIcon">
                                            <Box className="processIconInner">
                                                <img src={Process4} alt="process icon" />
                                            </Box>
                                            <Box className="processNumber">
                                                <span>4</span>
                                            </Box>
                                            <Box className="processDetailHeading processDetailHeading4">{t('Development2')}</Box>
                                        </Box>
                                    </Box>
                                    <Box className="processIcons processIcons5">
                                        <Box className="processIcon">
                                            <Box className="processIconInner">
                                                <img src={Process5} alt="process icon" />
                                            </Box>
                                            <Box className="processNumber">
                                                <span>5</span>
                                            </Box>
                                            <Box className="processDetailHeading processDetailHeading5">{t('Testing')}</Box>
                                        </Box>
                                    </Box>
                                    <Box className="processIcons processIcons6">
                                        <Box className="processIcon">
                                            <Box className="processIconInner">
                                                <img src={Process6} alt="process icon" />
                                            </Box>
                                            <Box className="processNumber">
                                                <span>6</span>
                                            </Box>
                                            <Box className="processDetailHeading processDetailHeading6">{t('Launch')}</Box>
                                        </Box>
                                    </Box>
                                    
                                </Box>
                            </Box>

                        </Col>

                    </Row>

                </Container>
            </Modal.Body>
            
        </Modal>
    );
};

export default CustomModal;
const AboutWrapper = styled.section`
    

`