import React, { useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { Box, SpanStyled, } from '../../Styled/Elements'
import { ListPoints, PrimaryHeading, ProcessSocialLinks, } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { TabsWrapper } from './Styled'
import styled from 'styled-components'
import { BeepList, BeepVisitList, MobileDevItems } from '../../data/Data'
import { Link } from 'react-router-dom'

const BeepVisitListing = ({ visitList }) => {
    const { t } = useTranslation();

    return (
        <>
            {visitList && visitList.length > 0 ? <Col lg={12} className='sthDesc mb-0'>
                <Box className="smallTitleHeading">
                    {t('Visit_Now')}
                </Box>
                <Box className='portfolioVisitListWrapper'>
                    {visitList.map((item, index) => (
                        <Link className="visitLink" to={item.beepLink} key={index} target="_blank">
                            {item.beepVisit}
                        </Link>
                    ))}
                </Box>
            </Col> : null}
        </>

    )
}

export default BeepVisitListing
