import React, { useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { Box, SpanStyled, } from '../../Styled/Elements'
import { ListPoints, PrimaryHeading, ProcessSocialLinks, } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { TabsWrapper } from './Styled'
import styled from 'styled-components'
import { BeepList, DaimounList, MobileDevItems } from '../../data/Data'

const DaimounListing = () => {
    const { t } = useTranslation();
    
    return (
        <Col lg={12} className='sthDesc'>
            <ul className='portfolioListWrapper'>
                {DaimounList.map((item, index) => {
                    console.log(item)
                    return (
                    <li className="unList" key={index}>
                        <SpanStyled color='var(--darkBlue)' size='16px' >
                            {t(item.ListTxt)}
                        </SpanStyled>
                    </li>
                )})}
            </ul>
        </Col>
        
    )
}

export default DaimounListing
