import React, { useState } from 'react'
import { ContactFormWrapper } from './Styled'
import { useForm } from "react-hook-form"
import { Box, LabelStyled } from '../../Styled/Elements'
import { ButtonWithIcon, ContactButton, ContactButton2 } from '../../Styled/CustomElements'
import { BTNICON, Email, FName, Message, NextArrow, NextArrow2, Phone } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ContactForm = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            // attachment: '',
            message: '',
            language: '',
        }
    })
    const getApi = async (data) => {
        try {
            setIsLoading(true);
            const response = await axios.post("https://fowl.sa/api/public/api/v1/contact-us-info", data);
            if (response.status === 200) {
                reset({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    language: '',
                });
                // toast.success("Your submission was successful.", { autoClose: 3000 });
                toast.success(t('submission'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    // className: 'message'
                })
                setIsLoading(false);
            } else {
                toast.error(t('submission_fail'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
            // alert("Submission has failed.");
            toast.error(t('submission_fail'), {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setIsLoading(false);
        }
    }
    const [phone, setPhone] = useState('');
    const handlePhoneChange = (e) => {
        // Allow only positive numbers
        const input = e.target.value;
        if (!isNaN(input) && input >= 0) {
            setPhone(input);
        }
    };


    const onSubmit = async (formData) => {
        console.log(formData);
        getApi(formData)
    };

    return (
        <>

            <ContactFormWrapper className='contactFormMain'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <Box className="field-box-wh">
                        
                        <Box className='field-box'>
                            <img src={FName} alt='Icon' />
                            <input className='input-field' placeholder={t('LastName')}  {...register("name", { required: true })} aria-invalid={errors.name ? "true" : "false"} />
                            {errors.name?.type === "required" && (<p className='error-message' role="alert">{t('name_error')}</p>)}
                        </Box>
                    </Box> */}
                    <Box className='field-box'>
                        <img src={FName} alt='Icon' />
                        {/* <LabelStyled color='var(--themeColor)' >{t('email')}</LabelStyled> */}
                        <input className='input-field' placeholder={t('FullName')} {...register("name", { required: true })} aria-invalid={errors.name ? "true" : "false"} />
                        {errors.name?.type === "required" && (<p className='error-message' role="alert">{t('name_error')}</p>)}
                    </Box>
                    <Box className="field-box-wh">
                        <Box className='field-box'>
                            <img src={Email} alt='Icon' />
                            {/* <LabelStyled color='var(--themeColor)' >{t('email')}</LabelStyled> */}
                            <input className='input-field' placeholder={t('emailPlaceholder')} type='email'{...register("email", { required: true })} aria-invalid={errors.email ? "true" : "false"} />
                            {errors.email && <p className='error-message' role="alert">{t('email_error')}</p>}
                        </Box>
                        <Box className='field-box'>
                            <img src={Phone} alt='Icon' />

                            {/* <LabelStyled color='var(--themeColor)' >{t('contactPhone')}</LabelStyled> */}
                            <input className='input-field' placeholder={t('phonePlaceholder')} type='text' value={phone} {...register("phone", { required: true })} aria-invalid={errors.phone ? "true" : "false"} onChange={handlePhoneChange} />
                            {errors.phone && <p className='error-message' role="alert">{t('phone_error')}</p>}
                        </Box>
                    </Box>
                    <Box className='field-box'>
                        {/* <LabelStyled color='var(--themeColor)' >{t('yourMessage')}</LabelStyled> */}
                        <img src={Message} alt='Icon' />
                        <textarea className='input-field' placeholder={t('messagePlaceholder')} cols="30" rows="6" {...register("message", { required: true })} aria-invalid={errors.message ? "true" : "false"} />
                        {errors.message && <p className='error-message' role="alert">{t('message_error')}</p>}
                    </Box>
                    <Box className='d-flex justify-content-end mt-3'>
                        {isLoading ? <p className='loading-btn'><span className='spinner'></span></p> : null}
                        <ButtonWithIcon
                            label={t('Send')} 
                            icon={NextArrow} 
                            portClass='btnMed'
                            padding= '6px 20px'
                            radius= '18px'
                        />
                        {/* <ContactButton2 type="submit" icon={BTNICON} label={t('Send')} className="proSetBtn" /> */}
                        {/* <p className='loading-btn'>{t('Request_send')} <span className='spinner'></span></p> */}
                    </Box>
                </form>
                <ToastContainer />

            </ContactFormWrapper >
        </>
    )
}

export default ContactForm