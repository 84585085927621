import styled from 'styled-components'

export const HeaderContainer = styled.header`
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    display: flex;
    z-index: 100;
    width: 100%;
    background: transparent;
    padding-top: 15px;
    padding-bottom: 15px;
    transition: all 0.5s cubic-bezier(.77,0,.18,1);
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        display:flex;  
        align-items: center;
        gap: 1px;
    }
    .nav-bar-list a{
        color:var(--darkBlue);
        padding:6px 15px;
        position: relative;
        font-size:16px;
        font-family: var(--regular);
        text-transform: math-auto;
        border-radius: 20px;
        &:hover{
            color:var(--white);
            background: var(--bgGradient);
        }
        &:focus{
            color: unset;
            background: unset;
        }
        &.active{
            color:var(--white);
            background: var(--bgGradient);
            font-family: var(--semiBold);
        }
    }
    .logo{
        cursor:pointer;
        svg{
            width: 78px;
            height: auto;
        }
    }
    &.sticky{
        // background: var(--white);
        box-shadow: 0px 4px 10px #626262ad;
        padding:7px 0px;
        z-index: 200;
    }
    .toggleBtn{
        display: none;
    }
}
`