import React, { useState } from 'react'
import Slider from 'react-slick'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { PrimaryHeading, ProcessSocialLinks, TabButton } from '../../Styled/CustomElements'
import { aboutIcon } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import { bannerItems } from '../../data/Data'
import TabFirst from './Tab1'
import TabSecond from './Tab2'
import { expertiseItems } from '../../data/Data'
import TabThird from './Tab3'

const About = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
      setActiveTab(tabNumber);
    };



    return (
        <AboutWrapper className='section-common aboutWrapper' id='about'>
            <Container className='container-fluid'>
                <Row className='leftRightColWrap about-wrap'>
                    <Box className='leftColIco leftColAbout'>
                        <img className='about-img' src={aboutIcon} />
                    </Box>
                    <Box className='rightColContent rightColContentAbout'>
                    


                    
                        <Box className='aboutHeading'>
                            <PrimaryHeading HlabelF={t("Who")} HlabelS={t("Are_WE")} />
                        </Box>
                        <Box className="tabWrapperMain">
                            <Box className="tabWrapper">
                                <TabButton label={t('About_Us')} className={activeTab === 1 ? 'active' : ''} onClick={() => handleTabClick(1)} />
                                <TabButton label={t('Our_Expertise')} className={activeTab === 2 ? 'active' : ''} onClick={() => handleTabClick(2)} />
                                <TabButton label={t('Why_Choose_Us')} className={activeTab === 3 ? 'active' : ''} onClick={() => handleTabClick(3)} />
                            </Box>
                            {activeTab === 1 && 
                        
                                <TabFirst />
                            }
                            {activeTab === 2 && 
                                <TabSecond />
                            }
                            {activeTab === 3 && 
                                <TabThird />
                            }
                        </Box>
                        <ProcessSocialLinks />

                    </Box>
                    
                </Row>
            </Container>
        </AboutWrapper>
    )
}

export default About
const AboutWrapper = styled.section`
    

`