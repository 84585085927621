import React, { useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { Box, SpanStyled, } from '../../Styled/Elements'
import { ListPoints, PrimaryHeading, ProcessSocialLinks, } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { TabsWrapper } from './Styled'
import styled from 'styled-components'
import { BeepList, BeepVisitList, MobileDevItems } from '../../data/Data'
import { Link } from 'react-router-dom'

const PrMoreDetail7 = () => {
    const { t } = useTranslation();
    
    return (
        <>
            <Col lg={12} className='sthDesc mb-2'>
                <Box className="smallTitleHeading">
                    {t('OverValues')}
                </Box>
                <SpanStyled size='15px' className='projectDesc'>
                    {t('projectModText7S')}
                </SpanStyled>
            </Col>
            
        </>
    )
}

export default PrMoreDetail7
