import React, { useState } from 'react'
import Slider from 'react-slick'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, SpanStyled } from '../../Styled/Elements'
import { PrimaryHeading, ProcessSocialLinks } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { TabsWrapper } from './Styled'
import { WhyChooseItems, expertiseItems } from '../../data/Data'
import styled from 'styled-components'
import { SliderArrowLeft, SliderArrowRight } from '../../Styled/AllImages'

const TabThird = () => {
    const { t } = useTranslation();



    return (
        <TabsWrapper className='TabsAboutWrapper'>
            <Box className='tabInnerHeading'>
                <PrimaryHeading HlabelF={t("Why")} HlabelS={t("Choose_Us")} size='23px' />
            </Box>
            <SpanStyled size='15px' className='tabTxt'>
                {t('tab3_Text')}
            </SpanStyled>
            <Box className='whyChooseUsContainer'>
                {WhyChooseItems.map((item, index) => (
                    <Box key={index} className='whyChooseUsCol'>
                        <Box className="whyChooseUsImg">
                            {item.img}
                        </Box>
                        <Box className="whyChooseUsTxt">{t(item.expertiseTxt)}</Box>
                    </Box>
                ))}
                
                
            </Box>
            {/* <ProcessSocialLinks /> */}
        </TabsWrapper>
    )
}

export default TabThird
