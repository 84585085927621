import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Box } from '../../Styled/Elements'
import { bannerItems } from '../../data/Data'
import styled from 'styled-components'

const Banner = () => {
    
    return (
        <ElementsWrapper className='globalElements'>
            <Container className='container-fluid'>
                <Box className='globalElement globalElement1'></Box>
                <Box className='globalElement globalElement2'></Box>
                <Box className='globalElement globalElement3'></Box>
                <Box className='globalElement globalElement4' ></Box>
                <Box className='globalElement globalElement5' ></Box>
                {/* <Box className='globalElement globalElementLine1'></Box>
                <Box className='globalElement globalElementLine2'></Box>
                <Box className='globalElement globalElementLine3'></Box> */}
                <Box className='element elementLine4'></Box>
                <Box className='element elementLine5'></Box>
            </Container>
        </ElementsWrapper>
    )
}

export default Banner
const ElementsWrapper = styled.section`
    height: calc(100vh - 82px);
    display: flex;
    align-items: center;
    position: absolute;
    top: 82px;
    .container-fluid{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index-5;
        height: 520px;
        margin: auto;
        // background-color: rgba(255, 0, 0, .09);
        .globalElement{
            position: absolute;
        }
    }
    
`