import React, { useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { Box, SpanStyled, } from '../../Styled/Elements'
import { PrimaryHeading, ProcessSocialLinks, } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { TabsWrapper } from './Styled'
import styled from 'styled-components'
import { SocialMediaItems } from '../../data/Data'

const SocialMedia = () => {
    const { t } = useTranslation();
    
    return (
        <Box className='devsServicesWrapper'>
            {SocialMediaItems.map((item, index) => (
                <Box className='devServicesCol' key={index}>
                    <Box className='devServicesImg'>
                        {item.img}
                    </Box>
                    <Box className='devServicesTitle'>{t(item.devTxt)}</Box>
                    
                </Box>
            ))}
        </Box>
    )
}

export default SocialMedia
