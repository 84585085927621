
import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Box } from './components/Styled/Elements';
import { privateRoute, privateRouteScroll } from './route/PrivateRoute';
import { Route, Routes, useLocation, Switch, Navigate } from 'react-router-dom';
import Header from './components/header/Header';
import GlobalElements from "./components/sections/elements/GlobalElements"
import { Oval } from 'react-loader-spinner';
// import { Navigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useArrowNavigation from './components/hooks/useArrowNavigation';


function App() {
	const location = useLocation();
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [loader, setLoader] = useState(false)
	// const [prevPath, setPrevPath] = useState(null);
	// const [prevPaths, setPrevPaths] = useState(null);
	const [pathTransitions, setPathTransitions] = useState({});
	const [redirected, setRedirected] = useState(false);

	useLayoutEffect(() => {
		setLoader(true)
	}, [])

	useEffect(() => {
		setLoader(false)
	}, [])
	
	
	useArrowNavigation();
		
	

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		// Set default class to "home" on load
		document.body.classList.add('home');
		// const defPath = location.pathname;
		// document.body.classList.add(defPath);
	
		// Cleanup function to remove event listeners
		return () => {
		  document.body.classList.remove('home');
		};
	  }, []);
	
	  useEffect(() => {
		// Update path transitions when location changes
		const currentPath = location.pathname;
		const prevPath = Object.keys(pathTransitions).length > 0 ? Object.keys(pathTransitions)[0] : null;
		//////////For Fade
		//setPrevPaths(location.pathname);
		
		////////////
		if (prevPath && currentPath !== prevPath) {
		  const newPathTransitions = { ...pathTransitions };
		  delete newPathTransitions[prevPath];
		  newPathTransitions[currentPath] = `${prevPath.substring(1)}-to-${currentPath.substring(1)}`;
		  setPathTransitions(newPathTransitions);
		} else if (!prevPath) {
		  setPathTransitions({ [currentPath]: null });
		}
		// document.body.classList.add(currentPath);
		// Add transition classes to body
		const transitionClass = pathTransitions[currentPath];
		if (transitionClass) {
		  document.body.classList.add(transitionClass);
		}
	
		// Remove transition classes when component unmounts
		return () => {
		  if (transitionClass) {
			document.body.classList.remove(transitionClass);
		  }
		};
	  }, [location, pathTransitions]);

	  


	  /*
	  Refresh page and Go Home 
	  useEffect(() => {
		const { pathname } = location;
		if (window.performance && window.performance.navigation.type === 1) {
		  // Page is refreshed, redirect to "/home"
		  if (pathname === '/about' || pathname === '/services') {
			window.location.href = '/home';
		  }
		}
	  }, [location]); */
	
	  // Define your routes dynamically here
	  /* const privateRoute = [
		{ path: '/', element: <Home /> },
		{ path: '/about', element: <About /> },
		{ path: '/services', element: <Services /> },
		// Add more routes as needed
	  ]; */

	  /* 
	  useEffect(() => {
		// Remove any existing classes from the body
		document.body.className = '';
	
		// Add class based on current route
		if (location.pathname === '/') {
		  document.body.classList.add('home');
		} else if (location.pathname === '/about') {
		  document.body.classList.add('about');
		} else if (location.pathname === '/services') {
		  document.body.classList.add('services');
		}
	
		// Remove class when navigating to a new page
		const unlisten = history.listen(() => {
		  document.body.className = '';
		});
	
		return () => {
		  unlisten();
		};
	  }, [location.pathname, history]);
 */

	  
	  /* 
	  useEffect(() => {
		// Remove any existing classes from the body
		document.body.className = '';
	
		// Add class based on current route
		if (location.pathname === '/') {
		  document.body.classList.add('home');
		} else if (location.pathname === '/about') {
		  document.body.classList.add('about');
		} else if (location.pathname === '/services') {
		  document.body.classList.add('services');
		}
	  }, [location.pathname]);
 */
/* 
	  useEffect(() => {
		// Extract the pathname from the location object
		const { pathname } = location;
	
		// Remove any existing classes from the body
		document.body.classList.remove('about', 'services', 'home');
	
		// Add a class to the body based on the current pathname
		if (pathname === '/about') {
		  document.body.classList.add('about');
		} else if (pathname === '/services') {
		  document.body.classList.add('services');
		} else {
		  // Add a default class if the pathname doesn't match any specific route
		  document.body.classList.add('home');
		}
	  }, [location]);
 */
	  

	/*
	useEffect(() => {
		// Set default class to "home" on load
		document.body.classList.add('home');
	
		// Cleanup function to remove event listeners
		return () => {
		  document.body.classList.remove('home');
		};
	  }, []);
	
	  useEffect(() => {
		// Add class to body based on the current and previous paths
		const currentPath = location.pathname;
		if (prevPath && currentPath !== prevPath) {
		  document.body.classList.remove(`${prevPath.substring(1)}-to-${currentPath.substring(1)}`);
		  document.body.classList.remove(`${currentPath.substring(1)}-to-${prevPath.substring(1)}`);
		  document.body.classList.add(`${prevPath.substring(1)}-to-${currentPath.substring(1)}`);
		}
		setPrevPath(currentPath);
	  }, [location, prevPath]);
 */
	  /* 
	useEffect(() => {
		// Add class to body based on the current and previous paths
		const currentPath = location.pathname;
		if (prevPath && currentPath !== prevPath) {
		  document.body.classList.remove(`${prevPath.substring(1)}-to-${currentPath.substring(1)}`);
		  document.body.classList.add(`${prevPath.substring(1)}-to-${currentPath.substring(1)}`);
		}
		setPrevPath(currentPath);
	  }, [location, prevPath]);
 */
	 
	/************* Add Body Class Previous
	useEffect(() => {
		// Add class to body based on the current path
		if (location.pathname === '/') {
			document.body.classList.add('animHome');
			document.body.classList.remove('animAbout');
		  } else if (location.pathname === '/about') {
			document.body.classList.add('animAbout');
			document.body.classList.remove('animHome');
		  }
		window.scroll({ top: 0, behaviour: 'smooth' })
	}, [location]);
	**************/
/* 
	useEffect(() => {
		// Add class to body based on the current path
		if (location.pathname === '/') {
		  document.body.classList.add('home');
		  document.body.classList.remove('about');
		} else if (location.pathname === '/about') {
		  document.body.classList.add('about');
		  document.body.classList.remove('home');
		}
	  }, [location]);
 */
	return (
		<>
			{loader ? (
					<Oval
					height={80}
					width={80}
					color="#9E50ED"
					wrapperStyle={{}}
					wrapperClass="site_loader"
					visible={true}
					ariaLabel='oval-loading'
					secondaryColor="#A168E6"
					strokeWidth={2}
					strokeWidthSecondary={2}
				  />
				) : 
			<Box className='main-artical'>
				<Header />
				<GlobalElements />
				
				{screenWidth > 991 ? (

					<Routes>
						
						{privateRoute.map((list, key) => (
							<Route to={key} {...list} />
						))}
						
						{/* Set the default route to "/home" */}
						<Route path="/" element={<Navigate to="/home" />} />
					</Routes>

				) : (
					<Routes>
						
						{privateRouteScroll.map((list, key) => (
							<Route to={key} {...list} />
						))}

					</Routes>
				)
				}
			</Box>
			}
		</>
	);
}

export default App;
