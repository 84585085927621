import React, { useState } from 'react'
import Slider from 'react-slick'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { PrimaryHeading, TabButton } from '../../Styled/CustomElements'
import { ClientHand, aboutIcon } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import { MainWrapper } from './Styled'
import ClientsSlider from './ClientsSlider'

const Clients = () => {
    const { t } = useTranslation();
    

    return (
        <MainWrapper className='section-common mainWrapper' id='clients'>
            <Container className='container-fluid'>
                <Row className='main-wrap'>
                    <Box className='mainHeading'>
                        <PrimaryHeading HlabelF={t("WhatOur")} HlabelS={t("ClientsSays")} customClass="text-center" />
                    </Box>
                    <SpanStyled size='15px' className='mb-4 text-center mainMainTxt'>
                        {t('cltText')}
                    </SpanStyled>
                    <Col md={12}>
                        <ClientsSlider />
                    </Col>
                    
                </Row>
                <img className='clientHand' src={ClientHand} />
            </Container>
        </MainWrapper>
    )
}

export default Clients
