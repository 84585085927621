import React, { useState } from 'react'
import Slider from 'react-slick'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { PrimaryHeading, ProcessSocialLinks, TabButton } from '../../Styled/CustomElements'
import { aboutIcon } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

import ServicesSlider from './ServicesSlider'

const Services = () => {
    const { t } = useTranslation();
    



    return (
        <MainWrapper className='section-common mainWrapper' id='services'>
            <Container className='container-fluid'>
                <Row className='main-wrap'>
                    <Box className='mainHeading'>
                        <PrimaryHeading HlabelF={t("Our_off")} HlabelS={t("Core_Offerings")} customClass="text-center" />
                    </Box>
                    <SpanStyled size='15px' className='mb-5 text-center mainMainTxt'>
                        {t('services_Text')}
                    </SpanStyled>
                    <Col md={12}>
                        <ServicesSlider />
                    </Col>
                    
                </Row>
            </Container>
        </MainWrapper>
    )
}

export default Services
const MainWrapper = styled.section`
    

`