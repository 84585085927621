import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { Col, Container, Row, Modal, Button } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { ButtonWithIcon, PrimaryHeading, TabButton } from '../../Styled/CustomElements'
import { Cross, FeatherBall, NextArrow, Process1, Process2, Process3, Process4, Process5, Process6, ServiceIcon1, Union1, Union2, aboutIcon } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

import BeepVisitListing from './BeepVisitList'
import { portfolioItems, portfolioModal, servicesModal } from '../../data/Data'




const CustomModal = ({ show, onHide, modalId }) => {

    const currentItem = portfolioModal.find(item => item.id == modalId)
    
    const { t } = useTranslation();
    return (
        <>
        <Modal 
            show={show && modalId !== null} 
            onHide={onHide}
            centered
            size="xl"
            className="custom-modal"
            // id={0}
        >
            
            <Modal.Body className='portfolioBody'>
                <Button variant="secondary" onClick={onHide} className='crossModal'>
                    <Cross />
                </Button>
                {modalId && (
                    <>
                        <section>
                            <Container className='containerPr container-fluid'>
                                <Row className='leftRightColWrap portfolio-wrap'>
                                    <Box className='leftColCntnt leftColPortfolio'>

                                        <Row className="leftColCntntRow">
                                            <Col lg={12}>
                                                <Box className='projectLogoName'>
                                                    <Box className='projectLogo'>
                                                        {currentItem.logoImg}
                                                    </Box>
                                                    <Box className='projectModTitle'>
                                                        {t(currentItem.projectName)}
                                                    </Box>
                                                </Box>
                                                
                                                
                                            </Col>
                                            <Col lg={12} className='sthDesc'>
                                                <Box className="smallTitleHeading">
                                                    {t('Scope')}
                                                </Box>
                                                <SpanStyled size='15px' className='projectDesc'>
                                                    {t(currentItem.projectDesc)}
                                                </SpanStyled>
                                                
                                            </Col>
                                            {currentItem.projectMoreDetail}
                                            {currentItem.projectList}
                                            {/* <Col lg={12} className='sthDesc'>
                                                <Box className="smallTitleHeading">
                                                    {t('Challenges')}
                                                </Box>
                                                {currentItem.projectList}
                                            </Col>
                                            <Col lg={12} className='sthDesc'>
                                                <Box className="smallTitleHeading">
                                                {t('Solution')}
                                                </Box>
                                                <SpanStyled size='15px' className='projectSolution'>
                                                {t(currentItem.projectSolution)}
                                                </SpanStyled>
                                            </Col> */}
                                            {/* <Col lg={12} className='sthDesc mb-0'>
                                                <Box className="smallTitleHeading">
                                                {t('Visit_Now')}
                                                </Box>
                                                {currentItem.projectVisit}
                                            </Col> */}
                                            <BeepVisitListing visitList={currentItem.projectVisit} />
                                            {/* <Col lg={12} className='sthDesc mb-1 techHdg'>
                                                <Box className="smallTitleHeading">
                                                {t('Technology')}
                                                </Box>
                                                
                                            </Col> */}
                                            
                                        </Row>
                                        
                                    </Box>
                                    {/* <Box className='rightColCntnt rightColContentBeep'>
                                        
                                    </Box> */}
                                    {/* {currentItem.projectTech} */}
                                </Row>

                            </Container>
                        </section>
                        <Box className='rightColImg rightColImgBeep'>
                            {currentItem.mainImg}
                        </Box>
                        
                    </>
                )}
            </Modal.Body>
            
        </Modal>
        
        </>
    );
};

export default CustomModal;
