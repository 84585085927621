import React, { useState, useEffect } from 'react'
import { Box } from "../components/Styled/Elements"
import About from "../components/sections/about/About"
import Banner from "../components/sections/banner/Banner"
import Services from '../components/sections/services/Services'
import Portfolio from '../components/sections/portfolio/Portfolio'
import Team from '../components/sections/team/Team'
import Testimonials from '../components/sections/testimonials/Testimonials'
import Clients from '../components/sections/clients/Clients'
import Contact from '../components/sections/contact/Contact'



const Index = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
    return (
        <>
            
            {screenWidth > 991 ? (
            <Banner />
            // <About />
            ) : (
                <>
            <Banner />
            <About />
            <Services />
            <Portfolio />
            {/* <Team /> */}
            <Testimonials />
            <Clients />
            {/* <Contact /> */}
            </>
                )}
        </>
    )
}

export default Index