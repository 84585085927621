import React, { useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { Box, SpanStyled, } from '../../Styled/Elements'
import { ListPoints, PrimaryHeading, ProcessSocialLinks, } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { TabsWrapper } from './Styled'
import styled from 'styled-components'
import { BeepList, BeepTechList, BeepVisitList, MobileDevItems } from '../../data/Data'
import { Link } from 'react-router-dom'

const BeepTechListing = () => {
    const { t } = useTranslation();
    
    return (
        <Box className='portfolioTechListWrapper'>
            {BeepTechList.map((item, index) => (
                <Link className="techList" key={index}>
                    {item.beepTechIcon}
                </Link>
            ))}
        </Box>
    )
}

export default BeepTechListing
