import React, { useState } from 'react'
import Slider from 'react-slick'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { Box, SpanStyled, } from '../../Styled/Elements'
import { PrimaryHeading, ProcessSocialLinks, } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import { TabsWrapper } from './Styled'
import { SliderArrowLeft, SliderArrowRight } from '../../Styled/AllImages'
import { servicesItems } from '../../data/Data'
import CustomModal from './ModalProcess'


const ServicesSlider = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(null); // State to track which modal is open

    const openModal = (modalId) => {
        setShowModal(modalId); // Set the state to the modal identifier
    };
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 4,
        swipeToSlide: true,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 1500,
        pauseOnHover: false,
        prevArrow: <SliderArrowLeft />,
        nextArrow: <SliderArrowRight />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1099,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <Box className='servicesColWrap'>
            
            <Slider {...settings}>
                {servicesItems.map((item, index) => (
                    <Box className='servicesCol' key={index}>
                        <Box className='servicesIcon'>
                            {item.img}
                        </Box>
                        <Box className='servicesHeading'>{t(item.serHeading)}</Box>
                        <Box className='servicesText'>
                            {t(item.serTxt)}
                        </Box>
                        <Box className='detailBtn'>
                            {/* {item.render()} */}
                            {item.render(openModal)} {/* Pass the openModal function */}
                        </Box>
                        <CustomModal 
                        key={index} 
                        show={showModal === item.id} // Show modal based on its id
                        onHide={() => setShowModal(null)} // Close modal function
                        modalId={item.id} // Pass the modal identifier
                    />
                    </Box>
                    
                ))}
            </Slider>

            
        </Box>
    )
}

export default ServicesSlider
