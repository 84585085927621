import React, { useState } from 'react'
import Slider from 'react-slick'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { PrimaryHeading, TabButton } from '../../Styled/CustomElements'
import { ClientHand, aboutIcon } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import { MainWrapper } from './Styled'
import TestimonialsSlider from './TestimonialsSlider'
// import ClientsSlider from './ClientsSlider'

const Testimonials = () => {
    const { t } = useTranslation();
    

    return (
        <MainWrapper className='section-common mainWrapper' id='testimonials'>
            <Container className='container-fluid'>
                <Row className='main-wrap'>
                    <Box className='mainHeading mb-4'>
                        <PrimaryHeading HlabelF={t("What_our")} HlabelS={t("Clients_have_to_say")} customClass="text-center" />
                    </Box>
                    {/* <SpanStyled size='15px' className='mb-4 text-center mainMainTxt'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    </SpanStyled> */}
                    <Col md={12}>
                        <TestimonialsSlider />
                    </Col>
                    
                </Row>
                {/* <img className='clientHand' src={ClientHand} /> */}
            </Container>
        </MainWrapper>
    )
}

export default Testimonials
