
import About from "../components/sections/about/About";
import Services from "../components/sections/services/Services";
import Portfolio from "../components/sections/portfolio/Portfolio";
import Index from "./Index";
import Team from "../components/sections/team/Team";
import Clients from "../components/sections/clients/Clients";
import Testimonials from "../components/sections/testimonials/Testimonials";
import Contact from "../components/sections/contact/Contact";


export const privateRoute = [
    
    {
        path: "/home",
        element: <Index />,
    },
    {
        path: "/about",
        element: <About />,
    },
    {
        path: "/services",
        element: <Services />,
    },
    {
        path: "/portfolio",
        element: <Portfolio />,
    },
    /* {
        path: "/team",
        element: <Team />,
    }, */
    {
        path: "/clients",
        element: <Clients />,
    },
    {
        path: "/testimonials",
        element: <Testimonials />,
    },
    /* {
        path: "/contact",
        element: <Contact />,
    }, */
]

export const privateRouteScroll = [

    
    {
        path: "/",
        element: <Index />,
    },
    

]